import axios from "axios";

export interface Payload {
  [key: string]: any;
}

const lookupAPI = async ( payload: Payload, lookupKey: string) => {
  const url = "https://hmlazrprd-api-01.azure-api.net/lookup";

  try {
    //const apiKey = process.env.REACT_APP_LOGIC_APP_LOOKUP_KEY;
    const apiKey = lookupKey;
       
    const config = {
      headers: {
        "Subscription-Key": apiKey,
        "Access-Control-Allow-Origin": "*"
      },
    };

    if (!apiKey) {
      throw new Error("API Key is not defined in environment variables");
    }

    const response = await axios.post(url, payload, config);

    return response.data;
  } catch (error) {
    console.error("API request failed:", error);
    throw error;
  }
};

export default lookupAPI;
